import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">

         
<path d="M1735 2441 c-8 -46 -13 -48 -56 -25 -42 21 -44 14 -8 -24 l31 -33
-41 -16 c-54 -21 -52 -32 6 -25 42 5 46 3 39 -14 -4 -10 -10 -32 -13 -49 l-5
-30 30 35 30 35 21 -25 c11 -14 20 -28 21 -32 0 -5 4 -8 9 -8 6 0 6 12 0 32
-16 57 -13 61 31 55 48 -6 52 6 8 24 -18 7 -35 14 -37 15 -2 2 8 19 23 39 29
38 26 43 -14 20 -37 -21 -50 -18 -50 13 0 49 -17 59 -25 13z m40 -75 c8 -13
13 -31 9 -40 -8 -20 -50 -21 -70 -1 -13 13 -13 17 2 40 20 31 39 32 59 1z"/>
<path d="M1355 2400 c-24 -27 -13 -68 22 -76 50 -13 86 29 60 70 -21 31 -57
34 -82 6z m58 -35 c-6 -17 -29 -20 -38 -6 -6 11 25 32 37 25 4 -3 5 -11 1 -19z"/>
<path d="M1210 2278 c-18 -13 -40 -34 -49 -48 l-16 -25 29 19 c41 26 182 47
221 32 16 -6 16 -8 3 -24 -8 -9 -28 -39 -44 -67 -32 -56 -92 -124 -79 -90 32
84 39 104 45 138 10 52 -11 44 -26 -10 -21 -71 -89 -205 -138 -270 -25 -35
-42 -63 -37 -63 5 0 49 39 98 88 84 82 137 151 180 233 l18 36 70 -1 c55 -1
80 4 118 22 54 27 64 48 12 26 -46 -19 -80 -18 -210 6 -137 25 -155 25 -195
-2z"/>
<path d="M1832 2140 c19 -37 19 -45 7 -88 -10 -36 -29 -63 -84 -118 -59 -59
-89 -80 -195 -132 -206 -101 -395 -154 -605 -168 -111 -8 -100 -19 30 -30 66
-6 73 -9 120 -54 46 -43 113 -90 129 -90 4 0 -1 12 -12 28 -31 43 -62 109 -55
116 19 19 43 2 67 -48 28 -57 85 -116 112 -116 14 0 15 14 12 98 l-3 97 25 3
25 3 -3 -101 c-4 -114 3 -122 62 -70 33 29 64 85 92 164 l14 38 -28 -7 c-34
-9 -38 -13 -24 -27 8 -8 7 -18 -4 -39 -8 -16 -11 -29 -7 -29 5 0 4 -4 -2 -8
-5 -4 -15 -16 -21 -26 -5 -11 -20 -26 -32 -34 -22 -14 -22 -14 -22 44 0 69
-17 108 -47 112 -34 5 -53 -33 -53 -105 0 -35 -4 -63 -9 -63 -12 0 -71 74 -71
88 0 6 -11 23 -26 37 l-25 25 23 9 c24 9 86 23 90 21 9 -4 283 117 333 147
121 73 206 169 221 249 6 34 8 36 20 19 31 -43 6 -114 -68 -188 -31 -31 -58
-70 -68 -97 l-16 -45 -70 -7 c-53 -5 -85 -15 -128 -40 -70 -40 -58 -48 23 -14
45 20 78 26 130 26 38 0 72 4 75 8 3 5 6 26 8 48 2 33 11 48 58 95 119 117
130 217 33 284 -21 14 -41 25 -44 25 -4 0 2 -18 13 -40z m-662 -508 c0 -4 -4
-12 -9 -17 -5 -6 -11 -23 -13 -40 l-3 -30 -33 34 c-28 29 -31 36 -18 43 9 5
20 7 24 4 4 -2 14 -1 22 5 19 11 30 11 30 1z"/>
<path d="M1382 2130 l-23 -30 29 0 c45 0 60 -24 38 -63 -16 -30 -16 -30 19 8
45 49 55 83 30 102 -32 23 -69 16 -93 -17z"/>
<path d="M1580 1943 c0 -100 1 -103 20 -91 11 7 15 15 9 19 -6 3 -9 38 -7 80
l3 74 35 0 35 0 6 -55 6 -55 1 66 2 66 -55 0 -55 0 0 -104z"/>
<path d="M1410 1875 c0 -95 2 -113 14 -109 11 4 12 12 4 35 -5 16 -8 57 -5 92
4 34 6 68 6 75 1 6 14 12 30 12 39 0 46 -14 50 -90 4 -95 11 -87 11 13 l0 87
-55 0 -55 0 0 -115z"/>
<path d="M1240 1803 c0 -51 3 -93 8 -93 4 0 6 36 4 80 -4 78 -4 79 22 86 47
12 56 1 56 -71 0 -37 4 -65 10 -65 6 0 10 33 10 78 l0 77 -55 0 -55 0 0 -92z"/>
<path d="M1070 1740 c0 -38 4 -70 8 -70 5 0 7 24 4 53 -4 59 10 81 50 75 20
-3 23 -9 24 -51 2 -62 14 -75 14 -14 0 25 3 52 6 61 5 13 -3 16 -50 16 l-56 0
0 -70z"/>
<path d="M1601 1631 c-13 -52 -33 -95 -66 -146 l-20 -30 50 29 c28 16 65 43
84 60 33 30 91 112 91 128 0 4 -28 8 -63 8 l-63 0 -13 -49z m83 11 c7 -12 -19
-47 -53 -71 l-29 -21 11 37 c6 21 15 44 20 51 11 14 43 16 51 4z"/>
<path d="M1007 1324 c-4 -4 -7 -43 -7 -86 0 -72 2 -78 20 -78 20 0 21 5 18 82
-3 75 -12 100 -31 82z"/>
<path d="M1730 1246 c0 -80 1 -86 20 -86 20 0 21 5 18 82 -3 70 -6 83 -20 86
-16 3 -18 -6 -18 -82z"/>
<path d="M510 1240 c0 -73 2 -80 20 -80 15 0 20 7 20 25 0 14 -4 25 -8 25 -4
0 -9 18 -10 40 -3 38 11 55 21 28 7 -21 17 -4 10 20 -3 15 -13 22 -29 22 -23
0 -24 -2 -24 -80z"/>
<path d="M600 1270 c-7 -27 -16 -47 -21 -44 -5 3 -9 -2 -9 -10 0 -18 16 -30
24 -18 3 5 8 24 11 42 7 42 12 50 31 50 9 0 14 -11 14 -36 0 -21 -4 -33 -10
-29 -5 3 -10 0 -10 -7 0 -7 -3 -23 -6 -35 -5 -19 -2 -23 15 -23 20 0 21 5 21
80 0 78 -1 80 -24 80 -21 0 -26 -7 -36 -50z"/>
<path d="M810 1295 c0 -8 -5 -15 -11 -15 -8 0 -7 -5 1 -16 8 -8 14 -34 14 -57
1 -36 4 -42 24 -45 15 -2 22 2 22 12 0 9 -4 16 -10 16 -5 0 -10 11 -10 24 0
14 -7 31 -15 40 -8 8 -15 17 -15 20 0 3 7 6 15 6 8 0 15 -8 15 -17 0 -12 3
-14 11 -6 8 8 8 17 0 32 -13 24 -41 28 -41 6z"/>
<path d="M685 1248 c4 -18 14 -45 21 -60 13 -25 13 -28 -1 -28 -8 0 -15 -7
-15 -15 0 -16 27 -20 48 -6 7 4 19 34 27 67 18 74 18 74 1 74 -18 0 -29 -31
-20 -54 8 -22 -4 -68 -17 -60 -5 3 -9 13 -7 22 2 10 -3 23 -12 30 -8 8 -9 11
-2 7 8 -4 12 3 12 24 0 25 -4 31 -21 31 -18 0 -20 -4 -14 -32z"/>
<path d="M884 1267 c-3 -8 -4 -34 -2 -58 3 -42 5 -44 36 -47 32 -3 62 11 62
29 0 14 -38 11 -44 -3 -3 -10 -9 -8 -19 5 -11 14 -16 15 -20 5 -3 -7 -6 1 -6
18 -1 20 5 34 15 38 26 10 42 7 48 -10 3 -8 2 -13 -2 -11 -4 3 -16 1 -27 -3
-15 -6 -10 -8 18 -8 35 0 38 2 35 27 -3 23 -8 26 -46 29 -30 2 -44 -1 -48 -11z"/>
<path d="M1066 1265 c-8 -22 -8 -22 9 -22 8 0 15 4 15 9 0 4 9 8 20 8 27 0 25
-28 -2 -33 -13 -2 -6 -4 15 -4 35 0 38 2 35 26 -3 23 -8 26 -44 29 -31 2 -43
-1 -48 -13z"/>
<path d="M1186 1265 c-11 -29 -6 -39 8 -19 14 18 15 17 14 -14 -1 -18 -6 -35
-11 -39 -6 -3 -7 -1 -3 5 3 6 2 13 -4 17 -6 4 -10 -4 -10 -18 0 -14 7 -28 16
-31 30 -11 72 -6 78 10 9 23 8 24 -14 24 -11 0 -20 -4 -20 -10 0 -5 -4 -10
-10 -10 -5 0 -10 18 -10 40 0 22 5 40 10 40 6 0 10 -4 10 -10 0 -5 9 -10 21
-10 14 0 19 5 17 18 -5 24 -83 30 -92 7z"/>
<path d="M1304 1267 c-3 -8 -4 -34 -2 -58 l3 -44 45 0 45 0 0 55 0 55 -43 3
c-30 2 -44 -1 -48 -11z m76 -25 c0 -7 -4 -10 -10 -7 -5 3 -10 2 -10 -4 0 -5 6
-11 12 -13 15 -5 -6 -30 -34 -41 -15 -5 -16 -2 -11 23 3 20 1 30 -6 30 -8 0
-8 4 1 14 16 18 58 17 58 -2z"/>
<path d="M1425 1219 c-1 -50 2 -59 17 -59 15 0 18 8 17 48 0 36 -2 43 -10 27
-8 -18 -8 -18 -6 5 2 22 6 25 35 22 17 -2 32 1 32 6 0 6 -19 10 -42 10 l-43 0
0 -59z"/>
<path d="M1525 1271 c-5 -10 6 -15 26 -13 4 1 9 -8 12 -18 3 -11 2 -20 -3 -20
-4 0 -10 -13 -12 -30 -4 -24 -1 -30 14 -30 17 0 19 7 16 58 -3 50 -6 57 -25
60 -11 2 -24 -2 -28 -7z"/>
<path d="M1610 1205 c0 -68 2 -75 20 -75 15 0 20 7 20 26 0 22 -2 25 -15 14
-13 -11 -15 -6 -15 34 0 25 4 46 9 46 5 0 16 3 25 6 11 4 16 0 17 -12 0 -11 3
-14 6 -6 7 17 23 5 23 -18 0 -11 -9 -24 -20 -30 -27 -14 -25 -32 3 -28 20 3
22 9 22 58 l0 55 -47 2 -48 2 0 -74z"/>
<path d="M1790 1265 c0 -9 7 -18 15 -21 8 -4 15 -10 15 -15 0 -4 -6 -6 -13 -3
-8 3 -12 -4 -12 -19 2 -36 12 -45 55 -45 l40 -1 0 60 c0 46 -3 59 -15 59 -11
0 -15 -11 -15 -36 0 -19 4 -33 9 -30 5 4 7 -2 4 -12 -2 -11 -14 -18 -27 -18
-21 0 -22 3 -18 48 4 44 3 48 -17 48 -12 0 -21 -6 -21 -15z"/>
<path d="M1926 1273 c-3 -4 -6 -26 -6 -50 0 -46 20 -67 43 -44 7 7 8 12 2 11
-24 -5 -32 2 -32 25 0 13 3 22 8 19 5 -3 9 2 9 10 0 11 9 16 27 16 21 0 24 -3
15 -12 -17 -17 -15 -28 6 -22 16 5 16 5 0 -9 -19 -15 -25 -32 -8 -22 6 3 10
-3 10 -14 0 -22 -30 -36 -60 -26 -14 5 -20 2 -20 -9 0 -12 10 -16 39 -16 22 0
43 5 46 11 4 6 9 39 11 74 l5 63 -45 0 c-24 0 -47 -2 -50 -5z"/>
<path d="M2104 1267 c-3 -8 -4 -34 -2 -58 l3 -44 45 0 c35 0 45 4 48 18 2 12
-3 17 -17 17 -12 0 -21 -4 -21 -10 0 -5 -6 -7 -12 -5 -7 3 -13 21 -13 40 0 21
5 35 13 35 6 0 12 -4 12 -10 0 -5 9 -10 21 -10 14 0 19 5 17 18 -4 23 -85 31
-94 9z"/>
<path d="M2224 1267 c-3 -8 -4 -34 -2 -58 l3 -44 45 0 45 0 0 55 0 55 -43 3
c-30 2 -44 -1 -48 -11z m56 -46 c0 -34 -3 -40 -20 -38 -16 1 -20 8 -20 33 0
29 9 41 33 43 4 1 7 -17 7 -38z"/>
<path d="M2340 1272 c0 -4 10 -16 22 -26 18 -15 20 -16 15 -2 -5 13 1 16 41
16 26 0 54 -5 62 -10 10 -6 11 -10 3 -10 -8 0 -13 -15 -13 -40 0 -34 3 -40 20
-40 19 0 21 5 18 58 l-3 57 -82 3 c-46 1 -83 -1 -83 -6z"/>
<path d="M1509 1223 c0 -25 -1 -26 -9 -8 -8 19 -9 19 -9 -2 -1 -12 -4 -28 -7
-37 -4 -12 0 -16 15 -16 18 0 21 6 21 45 0 48 -10 64 -11 18z"/>
<path d="M2410 1208 c-5 -44 -3 -48 16 -48 17 0 20 5 16 25 -2 14 -8 22 -12
20 -5 -3 -10 7 -12 22 -3 20 -5 14 -8 -19z"/>
<path d="M1063 1209 c-1 -13 3 -29 9 -36 15 -19 77 -17 85 2 8 23 -5 31 -29
16 -18 -11 -23 -10 -42 14 -22 27 -22 27 -23 4z"/>
<path d="M2351 1213 c-1 -12 -4 -28 -7 -37 -4 -12 0 -16 15 -16 17 0 21 6 21
31 0 16 -4 28 -9 25 -5 -4 -12 0 -14 6 -3 7 -6 3 -6 -9z"/>
<path d="M2040 1180 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M205 1023 c-4 -16 -8 -36 -10 -45 -2 -14 4 -18 26 -18 16 0 29 5 29
10 0 6 -7 10 -16 10 -13 0 -15 7 -10 35 8 42 -11 49 -19 8z"/>
<path d="M510 1028 c-11 -12 -20 -27 -20 -34 0 -17 27 -36 46 -32 26 5 28 25
2 24 -34 -1 -29 28 5 32 30 4 36 17 11 26 -22 8 -21 9 -44 -16z"/>
<path d="M1006 1033 c4 -10 8 -30 8 -45 1 -33 26 -38 26 -6 0 12 7 30 17 40 9
10 13 21 10 25 -4 4 -14 -2 -21 -13 -14 -18 -15 -18 -21 -1 -4 9 -12 17 -17
17 -6 0 -7 -7 -2 -17z"/>
<path d="M1250 1039 c0 -5 5 -9 11 -9 7 0 9 -12 5 -35 -4 -20 -3 -35 2 -35 6
0 13 16 16 35 4 19 11 35 16 35 6 0 10 4 10 8 0 5 -13 9 -30 10 -16 0 -30 -4
-30 -9z"/>
<path d="M1420 1039 c0 -5 5 -9 11 -9 7 0 9 -12 5 -35 -4 -21 -3 -35 3 -35 6
0 13 16 17 35 3 19 10 35 15 35 5 0 9 3 9 8 0 4 -14 7 -30 8 -17 1 -30 -3 -30
-7z"/>
<path d="M1499 1045 c-12 -20 -20 -85 -10 -85 5 0 11 10 13 23 3 25 23 21 22
-5 0 -10 3 -18 8 -18 4 0 8 14 8 30 0 19 -5 30 -14 30 -8 0 -16 8 -18 18 -2 9
-6 13 -9 7z"/>
<path d="M1664 1028 c3 -13 6 -33 6 -45 0 -32 24 -29 40 5 l14 27 4 -27 c5
-39 25 -35 42 10 8 20 16 40 18 45 2 4 -1 7 -6 7 -6 0 -16 -15 -22 -32 l-13
-33 -6 30 c-7 35 -21 33 -37 -4 l-11 -26 -7 33 c-4 17 -12 32 -18 32 -6 0 -8
-10 -4 -22z"/>
<path d="M1916 1019 c-8 -44 -8 -61 3 -55 11 7 20 76 10 81 -3 3 -9 -9 -13
-26z"/>
<path d="M1990 1036 c0 -8 -10 -16 -22 -18 -17 -2 -24 -11 -26 -30 -3 -25 0
-28 27 -28 25 0 31 4 31 23 0 12 3 32 6 45 4 13 2 22 -5 22 -6 0 -11 -6 -11
-14z"/>
<path d="M2154 1039 c-4 -6 -10 -27 -14 -45 -6 -26 -5 -34 6 -34 8 0 14 10 14
25 0 14 6 25 13 25 8 0 7 4 -3 10 -10 6 -11 10 -2 10 6 0 12 5 12 10 0 13 -17
13 -26 -1z"/>
<path d="M2220 1041 c0 -6 5 -11 10 -11 7 0 8 -13 4 -35 -4 -23 -2 -35 5 -35
6 0 11 11 11 24 0 14 7 32 16 40 13 14 13 16 1 16 -9 0 -22 3 -31 6 -9 3 -16
1 -16 -5z"/>
<path d="M2290 1037 c0 -8 -3 -29 -6 -46 -4 -19 -2 -31 4 -31 6 0 12 10 14 23
4 27 24 24 21 -4 -2 -10 2 -19 7 -19 6 0 10 14 10 30 0 24 -4 30 -21 30 -11 0
-18 4 -14 9 3 5 1 12 -5 16 -5 3 -10 0 -10 -8z"/>
<path d="M2466 1038 c-3 -7 -6 -28 -9 -45 -3 -30 -1 -33 25 -33 15 0 28 5 28
10 0 6 -7 10 -16 10 -12 0 -15 7 -12 30 5 31 -8 53 -16 28z"/>
<path d="M343 1029 c-15 -15 -3 -69 14 -69 12 0 13 3 4 14 -9 11 -8 17 2 28
11 11 11 16 2 25 -8 7 -16 8 -22 2z"/>
<path d="M923 1029 c-14 -14 -3 -69 13 -69 8 0 14 5 14 10 0 6 -5 10 -12 10
-7 0 -5 7 5 18 12 14 13 19 2 29 -8 7 -16 8 -22 2z"/>
<path d="M2050 1015 c-25 -46 28 -76 64 -36 25 28 13 61 -23 61 -19 0 -32 -8
-41 -25z m58 -8 c4 -21 -25 -34 -40 -19 -8 8 -8 16 2 27 16 19 34 15 38 -8z"/>
<path d="M270 1005 c-17 -21 -5 -45 22 -44 13 0 17 3 10 6 -10 4 -9 8 4 17 18
14 12 36 -10 36 -8 0 -19 -7 -26 -15z"/>
<path d="M415 1009 c-4 -6 -4 -13 -1 -16 3 -3 1 -12 -5 -20 -9 -10 -7 -13 9
-13 26 0 39 19 21 31 -10 6 -11 9 -1 9 6 0 12 5 12 10 0 13 -27 13 -35 -1z"/>
<path d="M584 1005 c-8 -21 2 -45 19 -45 21 0 38 25 31 44 -8 20 -43 21 -50 1z"/>
<path d="M648 990 c-3 -16 -1 -30 4 -30 4 0 8 9 8 20 0 11 7 20 16 20 11 0 14
-6 9 -20 -4 -11 -2 -20 4 -20 6 0 11 14 11 30 0 25 -4 30 -24 30 -19 0 -25 -6
-28 -30z"/>
<path d="M716 998 c-8 -32 -7 -38 4 -38 6 0 10 9 10 20 0 11 5 20 10 20 6 0
10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 14 10 30 0 25 -4 30 -24 30 -16 0 -26
-7 -30 -22z"/>
<path d="M784 1005 c-10 -24 4 -45 28 -44 21 0 21 1 4 8 -19 7 -19 8 2 13 15
4 20 11 16 22 -8 20 -43 21 -50 1z"/>
<path d="M863 1013 c-18 -7 -16 -42 2 -49 8 -3 20 -2 27 2 9 6 6 10 -12 15
l-25 7 23 7 c12 4 22 11 22 16 0 10 -17 11 -37 2z"/>
<path d="M1082 1003 c-13 -24 -4 -43 18 -43 21 0 40 28 33 48 -7 18 -41 15
-51 -5z"/>
<path d="M1150 990 c0 -25 4 -30 25 -30 15 0 25 6 25 14 0 8 3 21 6 30 3 9 2
16 -4 16 -5 0 -12 -9 -15 -20 -3 -11 -10 -20 -17 -20 -7 0 -9 8 -5 20 4 11 2
20 -4 20 -6 0 -11 -13 -11 -30z"/>
<path d="M1320 1005 c-16 -20 -6 -45 19 -45 22 0 41 27 34 48 -6 17 -38 15
-53 -3z"/>
<path d="M1560 1000 c-14 -26 -5 -40 23 -40 18 0 19 2 7 10 -13 8 -12 10 3 10
10 0 17 8 17 20 0 27 -36 27 -50 0z"/>
<path d="M1800 1005 c-15 -18 -6 -45 13 -45 20 0 40 27 33 45 -7 19 -30 19
-46 0z"/>
<path d="M1860 989 c0 -17 4 -28 10 -24 6 3 10 11 10 17 0 6 8 17 18 24 15 12
14 13 -10 14 -25 0 -28 -4 -28 -31z"/>
<path d="M2360 1005 c-16 -20 -6 -45 19 -45 20 0 37 32 26 50 -9 15 -31 12
-45 -5z"/>
<path d="M2554 1004 c3 -9 6 -22 6 -30 0 -24 24 -15 42 16 9 17 13 30 7 30 -5
0 -12 -7 -15 -15 -4 -8 -10 -15 -15 -15 -5 0 -9 7 -9 15 0 8 -5 15 -11 15 -6
0 -8 -7 -5 -16z"/>
<path d="M2656 1004 c-9 -24 -7 -44 4 -44 6 0 10 9 10 20 0 11 5 20 11 20 6 0
9 -9 6 -20 -3 -11 -2 -20 3 -20 10 0 21 41 14 52 -9 14 -42 9 -48 -8z"/>
<path d="M2727 1013 c-13 -12 -7 -42 11 -52 16 -10 16 -10 -2 -11 -16 0 -18
-2 -7 -12 10 -11 16 -8 32 11 28 34 25 71 -4 71 -13 0 -27 -3 -30 -7z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
